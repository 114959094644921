"use client";

import { useEffect, useState } from "react";

import { useFeatureFlagEnabled, usePostHog } from "posthog-js/react";

import styles from "./banner.module.css";

interface BannerPayload {
  text: string;
  link?: {
    url: string;
    label: string;
  };
  backgroundColor?: string;
  textColor?: string;
  expiryDate?: string;
}

export default function Banner() {
  const [showBanner, setShowBanner] = useState(false);
  const [bannerContent, setBannerContent] = useState("");
  const posthog = usePostHog();
  const isBannerEnabled = useFeatureFlagEnabled("show-banner");

  useEffect(() => {
    if (isBannerEnabled) {
      const payload = posthog.getFeatureFlagPayload("show-banner") as BannerPayload | null;

      if (payload) {
        const content = payload.link
          ? `${payload.text}<a href="${payload.link.url}" target="_blank" rel="noopener noreferrer">${payload.link.label}</a>`
          : payload.text;

        setBannerContent(content);
      }

      setShowBanner(true);
      posthog?.capture("banner_viewed");
    }
  }, [isBannerEnabled, posthog]);

  const handleClose = () => {
    setShowBanner(false);
    posthog?.capture("banner_closed");
  };

  if (!showBanner) return null;

  return (
    <div className={styles.banner}>
      <p dangerouslySetInnerHTML={{ __html: bannerContent }}></p>
      <button onClick={handleClose} className={styles.closeButton}>
        ×
      </button>
    </div>
  );
}
