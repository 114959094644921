import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { getSessionData } from "@/utils/state";

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_BACKEND_URL,
});
const authLink = setContext((_, { headers }) => {
  const user = getSessionData("currentUser");
  return {
    headers: {
      ...headers,
      authorization: user ? `JWT ${user.userToken}` : "",
    },
  };
});

const cache = new InMemoryCache();
const link = authLink.concat(httpLink);

export const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: "no-cache",
    },
    watchQuery: {
      fetchPolicy: "no-cache",
    },
    mutate: {
      fetchPolicy: "no-cache",
    },
  },
  fetchOptions: {
    credentials: "same-origin",
  },
});
