"use client";

import { useEffect } from "react";

import { usePathname, useSearchParams } from "next/navigation";
import posthog from "posthog-js";
import { PostHogProvider as Provider } from "posthog-js/react";

import POSTHOG_CONFIG from "@/config/posthog";

export default function PostHogProvider({ children }: { children: React.ReactNode }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    // Initialize PostHog
    posthog.init(POSTHOG_CONFIG.API_KEY, {
      api_host: POSTHOG_CONFIG.HOST,
      capture_pageview: false, // Disable automatic capture
    });
  }, []);

  // Track pageviews
  useEffect(() => {
    if (pathname) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }

      // Capture pageview
      posthog.capture("$pageview", {
        current_url: url,
        path: pathname,
      });
    }
  }, [pathname, searchParams]);

  return <Provider client={posthog}>{children}</Provider>;
}
