export const API_SCOPE = process.env.NEXT_PUBLIC_LOGIN_SCOPE;
const authorityDomain = process.env.NEXT_PUBLIC_AUTHORITY_DOMAIN;
const signUpSignInAuthority = process.env.NEXT_PUBLIC_SUSI_AUTHORITY;
const msalClientId = process.env.NEXT_PUBLIC_MSAL_CLIENT_ID;

export const msalConfig = {
  auth: {
    clientId: msalClientId,
    authority: signUpSignInAuthority,
    knownAuthorities: [authorityDomain],
    redirectUri: "/",
    postLogoutRedirectUri: "/",
    scope: API_SCOPE,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    // Optional
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: [API_SCOPE],
};

export const userDataLoginRequest = {
  scopes: ["user.read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
