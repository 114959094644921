"use client";

import React from "react";

import { redirect } from "next/navigation";

export default function Home({
  params: { locale },
}: Readonly<{
  params: { locale: string };
}>) {
  const redirectLocale = locale !== undefined ? locale : "en";
  redirect(`${redirectLocale}/login`);
}
