"use client";

import { ApolloProvider } from "@apollo/client";
import { RecoilRoot } from "recoil";

import { client } from "@/apollo/apolloClient";
import MyMsalProvider from "@/msal/MyMsalProvider";

const environment = process.env.NEXT_PUBLIC_TARGET_ENV;

const RootWrapper = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  if (environment === "airgapped") {
    return (
      <RecoilRoot>
        <ApolloProvider client={client}>{children}</ApolloProvider>
      </RecoilRoot>
    );
  }
  return (
    <RecoilRoot>
      <MyMsalProvider>
        <ApolloProvider client={client}>{children}</ApolloProvider>
      </MyMsalProvider>
    </RecoilRoot>
  );
};

export default RootWrapper;
