"use client";

import { useEffect } from "react";

import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";

import { initializeMsal, msalInstance } from "@/msal/msal";

export default function MyMsalProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    initializeMsal();
  }, []);

  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
}
