const SESSION_EXPIRY_HOURS = process.env.NEXT_PUBLIC_SESSION_EXPIRY_HOURS;

export default function setSessionData(key, token) {
  var responseStatus = false;
  switch (key) {
    case "currentUser": {
      const currentDateTime = new Date();
      const updateDateTime = new Date();
      const expireDateTime = new Date(updateDateTime.setHours(updateDateTime.getHours() + SESSION_EXPIRY_HOURS));
      const currentTimestamp = Math.floor(currentDateTime.getTime() / 1000);
      const expireTimeStamp = Math.floor(expireDateTime.getTime() / 1000);

      const initialState = {
        isLogin: true,
        loginTime: currentTimestamp,
        expirationTime: expireTimeStamp,
        userToken: token,
      };
      window.sessionStorage.setItem(key, JSON.stringify(initialState));
      responseStatus = true;
      break;
    }

    default: {
      responseStatus = false;
      break;
    }
  }
  return responseStatus;
}

export function getSessionData(key, type = "all") {
  var responseObject = null;
  try {
    if (window.sessionStorage.getItem(key)) {
      var response;
      response = JSON.parse(window.sessionStorage.getItem(key));
      switch (type) {
        case "all": {
          responseObject = response ? response : null;
          break;
        }
        case "userToken": {
          responseObject = response.userToken ? response.userToken : null;
          break;
        }
        default: {
          responseObject = null;
          break;
        }
      }
    }
  } catch (e) {
    responseObject = null;
  }
  return responseObject;
}

export function removeSessionData(key) {
  window.sessionStorage.removeItem(key);
}
